.payment_header div {
  overflow-wrap: break-word ;
}

.payment-row div {
  overflow-wrap: break-word ;
}

.date-picker-container {
  display: flex;
  flex-direction: column;
}

.date-picker-container input {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.date-picker-container input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}


@media(max-width: 991px) {
}

@media(max-width: 768px) {
  .mt-3 {
      gap: 1rem;
  }

  .payment_header {
    display: none !important;
  }
}